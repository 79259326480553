// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    current_env: 'prod',
    needl_url: 'https://needl.co',
    needl_front_end_url: 'https://app.needl.co',
    needl_back_end_url: 'https://backend.needl.co',
    needl_bo_url: 'https://bo.needl.co',
    googleApiKey: 'AIzaSyCRyO_vkKAKILFgpm7CaMpRnDArLMHlndY',
    gtmKey : 'GTM-KLRPFJV',
    cacheKey: 'f3d75468f25b1af46dc12865a7eb451b83ec678d',
    salesIQKey: 'abee0380a54b5b6e4a5a5c855d40fc7542bd682d32621befaa9ae1da1dbe1032',
    appVersion: 'f3d75468f25b1af46dc12865a7eb451b83ec678d'
};
